<template>
  <div class="main">
    <b-tabs>
      <b-tab @click="callBanks()">
        <template #title>
          <strong style="font-weight: 500; color: black">{{ $t("Banks") }}</strong>
        </template>
        <button
          v-b-modal.add-bank-account
          class="button"
          style="margin-top: 1%;"
        >
          <b-icon-plus />
          {{ $t('AddBank') }}
        </button>
        <div style="width: 100%;padding-top: 16px; margin-top: 0px;">
          <table
            ref="exportable_table"
            class="team_table"
          >
            <thead>
              <tr>
                <th>
                  {{ $t('BankName') }}
                </th>
                <th>
                  {{ $t('PhoneNumber') }}
                </th>
                <th>
                  {{ $t('Email') }}
                </th>
                <th>
                  {{ $t('Street') }}
                </th>
                <th>
                  {{ $t('HouseNumber') }}
                </th>
                <th>
                  {{ $t('DoorNumber') }}
                </th>
                <th>
                  {{ $t('PostalCode') }}
                </th>
                <th>
                  {{ $t('City') }}
                </th>
                <th>
                  {{ $t('Country') }}
                </th>
                <th>
                  {{ $t('Edit') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(bank, index) in getBankDetails"
                :key="index"
              >
                <td>{{ bank.name }}</td>
                <td>+{{ countryCallingCode[bank.countryCode] }} {{ bank.phoneNumber }}</td>
                <td>{{ bank.email }}</td>
                <td>{{ bank.street }}</td>
                <td>{{ bank.houseNumber }}</td>
                <td>{{ bank.doorNumber }}</td>
                <td>{{ bank.postalCode }}</td>
                <td>{{ bank.city }}</td>
                <td>{{ bank.country }}</td>
                <td>
                  <p
                    v-b-modal.edit-bank-account
                    @click="detailsBank(bank)"
                  >
                    <b-icon-pencil />
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-tab>
      <b-tab @click="activeAcc()">
        <template #title>
          <strong style="font-weight: 500; color: black">{{ $t("Accounts") }}</strong>
        </template>
        <div style="display: flex; justify-content: space-between">
          <button
            v-b-modal.add-finance-account
            class="button"
            style="margin-top: 1%;"
            @click="callBanks"
          >
            <b-icon-plus />
            {{ $t('AddAccount') }}
          </button>
          <div style="display: flex; gap: 2%; margin-top: 1%;">
            <button
              class="button"
              @click="activeAcc"
            >
              {{ $t('Active') }}
            </button>
            <button
              class="button"
              @click="inActiveAcc"
            >
              {{ $t('Inactive') }}
            </button>
          </div>
        </div>
        <div style="width: 100%;padding-top: 16px; margin-top: 0px;">
          <table
            ref="exportable_table"
            class="team_table"
          >
            <thead>
              <tr>
                <th>
                  {{ $t('BankName') }}
                </th>
                <th>
                  {{ $t('Name') }}
                </th>
                <th>
                  {{ $t('IBAN') }}
                </th>
                <th>
                  {{ $t('CreditCardType') }}
                </th>
                <th>
                  {{ $t('Activate/Deactivate') }}
                </th>
                <th>
                  {{ $t('Edit') }}
                </th>
                <th>
                  {{ $t('ViewTransactions') }}
                </th>
              </tr>
            </thead>
            <tbody v-if="isActive == true">
              <tr
                v-for="(account, index) in getAccountDetails"
                :key="index"
              >
                <td>{{ account.bankName }}</td>
                <td>{{ account.name }}</td>
                <td>{{ account.iban }}</td>
                <td>{{ account.creditCardType }}</td>
                <td>
                  <p
                    style="position: relative; z-index:0; display: flex; flex-direction: column;"
                    @click="deactiveOrActiveAccount(account)"
                  >
                    <button
                      v-b-modal.account-deactivate
                      class="button"
                    >{{ $t('Deactivate') }}</button>
                  </p>
                </td>
                <td>
                  <p
                    v-b-modal.edit-finance-account
                    @click="detailsAccount(account)"
                  >
                    <b-icon-pencil />
                  </p>
                </td>
                <td
                  v-b-toggle.sidebar-right
                  @click="goToTransactions(account.accountId, account.name)"
                >
                  <p>
                    <b-icon-arrow-right
                      class="bigger-icon"
                      color="black"
                    />
                  </p>
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="isActive == false">
              <tr
                v-for="(account, index) in getAccountDetails"
                :key="index"
              >
                <td>{{ account.bankName }}</td>
                <td>{{ account.name }}</td>
                <td>{{ account.iban }}</td>
                <td>{{ account.creditCardType }}</td>
                <td>
                  <p
                    style="position: relative; z-index:0; display: flex; flex-direction: column;"
                    @click="deactiveOrActiveAccount(account)"
                  >
                    <button
                      v-b-modal.account-activate
                      class="button"
                    >Activate</button>
                  </p>
                </td>
                <td>
                  <button
                    v-b-modal.edit-finance-account
                    class="editButton"
                    @click="detailsAccount(account)"
                  >
                    {{ $t('Edit') }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-tab>
      <b-tab>
        <template #title>
          <strong style="font-weight: 500; color: black">{{ $t("BudgetType") }}</strong>
        </template>
        <!-- <button
          v-b-modal.add-expense-type
          class="button"
          style="margin-top: 1%;"
        >
          Configure expenses
        </button> -->
        <div style="display: flex; flex-direction: row;">
          <BudgetType @budgetTypeId="handleBudgetTypeId" />
          <BudgetSubType
            :selected-budget-type="selectedBudgetType"
            :budget-type-id="budgetTypeId"
            style="margin-left: 20px"
          />
        </div>
      </b-tab>
    </b-tabs>
    <div
      v-if="isSidebarOpen"
      class="backdrop"
    />
    <AddFinanceAccount @addFinance="addFinance" />
    <EditFinanceAccount
      :account-detailss="accountDetailss"
      @editAccount="editAccount"
    />
    <EditBankAccount
      :bank-detailss="bankDetailss"
      @editBank="editBank"
    />
    <AddBankAccount @addBank="addBank" />
    <DeactivateAccount
      :account-user="accountUser"
      @deactivate="deactivateAccounts"
    />
    <ActivateAccount
      :account-user="accountUser"
      @active="activateAccount"
    />
    <AddTransaction />
    <!-- @addTransactionn="addTransactionn" -->
    <ViewTransactions
      :account-name="name"
      :account-id="accountId"
      @onCancelToggle="onCancelToggle"
    />
  </div>
</template>

<script>
import AddFinanceAccount from '@/components/employeePayments/modals/AddFinanceAccount.vue'
import EditFinanceAccount from '@/components/employeePayments/modals/EditFinanceAccount.vue'
import AddBankAccount from '@/components/employeePayments/modals/AddBankAccount.vue'
import EditBankAccount from '@/components/employeePayments/modals/EditBankAccount.vue'
import DeactivateAccount from '@/components/employeePayments/modals/DeactivateAccount.vue'
import ActivateAccount from '@/components/employeePayments/modals/ActivateAccount.vue'
import AddTransaction from '@/components/employeePayments/modals/AddTransaction.vue'
import ViewTransactions from '@/components/employeePayments/ViewTransactions.vue'
import BudgetType from '@/components/employeePayments/BudgetType.vue'
import BudgetSubType from '@/components/employeePayments/BudgetSubType.vue'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import { countryCallingCodes } from '@/countryCodes'

export default {
  components: {
    AddFinanceAccount,
    EditFinanceAccount,
    AddBankAccount,
    EditBankAccount,
    DeactivateAccount,
    ActivateAccount,
    AddTransaction,
    ViewTransactions,
    BudgetType,
    BudgetSubType,
  },
  data() {
    return {
      countryCallingCode: countryCallingCodes,
      tabIndex: 0,
      bankDetailss: null,
      accountDetailss: null,
      isActive: true,
      accountUser: null,
      accountId: null,
      name: null,
      isSidebarOpen: false,
      selectedBudgetType: null,
      budgetTypeId: null,
    }
  },
  computed: {
    ...mapGetters(['getBankAccount', 'getBankDetails', 'getAccountDetails', 'getExpenseTypes']),
  },
  watch: {
    tabIndex(value) {
      if (value == 0) {
        this.bankAccounts()
      } else if (value == 1) {
        this.accountDetails({ isActive: true, bankId: null })
      }
    },
  },
  mounted() {
    this.bankAccounts()
    this.bankDetails({ isActive: true })
    this.accountDetails({ isActive: true, bankId: null })
    // this.expenseTypes()
    console.log()
  },
  methods: {
    ...mapActions(['addBankAccount',
      'bankAccounts',
      'addFinanceAccount',
      'bankDetails',
      'accountDetails',
      'editBankAccount',
      'editFinanceAccount',
      'accountActivation',
      'editBankAccountStatus',
      'transactionByAccount',
      'addTransaction',
      'expenseTypes',
      'addExpenseType',
      'addBudgetType',
    ]),
    handleBudgetTypeId(budget) {
      this.selectedBudgetType = budget;
      this.budgetTypeId = budget.id;
    },
    onCancelToggle() {
      this.isSidebarOpen = false
    },
    activateAccount(value) {
      this.editBankAccountStatus({
        object: value,
        successCallback: () => {
          this.accountDetails({ isActive: false, bankId: null })
        },
      })
    },
    deactivateAccounts(value) {
      this.editBankAccountStatus({
        object: value,
        successCallback: () => {
          this.accountDetails({ isActive: true, bankId: null })
        },
      })
    },
    deactiveOrActiveAccount(value) {
      this.accountUser = value.accountId
    },
    transactionById(value) {
      this.accountIdTransaction = value.accountId
    },
    async addBank(obj) {
      await this.addBankAccount({
        object: obj,
        successCallback: () => {
          this.bankAccounts();
        },
      })
    },
    async addFinance(obj) {
      await this.addFinanceAccount({
        object: obj,
        successCallback: () => {
          this.accountDetails({ isActive: true, bankId: null })
        },
      })
    },
    async editBank(obj) {
      await this.editBankAccount({
        object: obj,
        successCallback: () => {
          this.bankDetails({ isActive: true })
        },
      })
    },
    async editAccount(obj) {
      await this.editFinanceAccount({
        object: obj,
        successCallback: () => {
          if (this.isActive == true) {
            this.accountDetails({ isActive: true, bankId: null })
          } else {
            this.accountDetails({ isActive: false, bankId: null })
          }
        },
      })
    },
    async addBudgetTypee(obj) {
      await this.addBudgetType({
        object: obj,
        successCallback: () => {
        },
      })
    },
    async addBudgetSubTypee(obj) {
      await this.addBudgetSubType({
        object: obj,
        successCallback: () => {
        },
      })
    },
    async addCamaping(obj) {
      await this.addCampaing({
        object: obj,
        successCallback: () => {
          this.campaingPagination({
            pageNumber: 1,
            pageSize: this.pageSize,
          });
        },
      })
    },
    callBanks() {
      this.bankAccounts()
    },
    detailsBank(value) {
      console.log(value)
      this.bankDetailss = value
    },
    detailsAccount(value) {
      this.accountDetailss = value
    },
    activeAcc() {
      this.accountDetails({ isActive: true, bankId: null })
      this.isActive = true
    },
    inActiveAcc() {
      this.accountDetails({ isActive: false, bankId: null })
      this.isActive = false
    },
    goToTransactions(item, accountName) {
      this.accountId = item
      this.name = accountName
      this.isSidebarOpen = true
      this.transactionByAccount({
        accountId: item, transaction: 'ALL', year: moment().format('YYYY'), month: moment().format('MM'), pageNumber: 1, pageSize: 15,
      })
    },
  },
}
</script>

<style scoped>
.hover-row td{
    padding: 9px;
    font-weight: 400;
    color: black;
}

.main{
    display: flex;
    flex-direction: column;
}


.team_table td {
  padding: 16px 9px !important;
}

.team_table thead {
  border-bottom: 1px solid #dcdfe6;
  color: #606266;
  text-align: left;
}

.team_table {
  width: 100%;
  font-size: 12px;
  text-align: left;
}

.hover-row {
  border-bottom: 1px solid #dcdfe6;
  color: #606266;
  text-align: left;
}

.bigger-icon {
  font-size: 24px;
}

@media screen and (max-width: 1700px) {
  .main{
    padding: 75px 10px 15px 90px;
  }
}

@media screen and (max-width: 1200px) {
  .main{
    padding: 75px 10px 15px 10px;
  }
}
</style>
